.group {
  background-color: light-dark(
    var(--mantine-color-gray-0),
    var(--mantine-color-dark-7)
  );
  border-radius: var(--mantine-radius-sm);
  margin-bottom: var(--mantine-spacing-xs);
}

.groupLabel {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));

  &::after {
    display: none;
  }
}

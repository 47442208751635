.header {
  position: sticky;
  left: 0;
  background-color: var(--mantine-color-body);
  transition: box-shadow 150ms ease;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-right: rem(10px) solid var(--mantine-color-gray-3);
  }
}

.scrolled {
  box-shadow: var(--mantine-shadow-sm);
}

.progressSection {
  &:not(:first-of-type) {
    border-left: rem(3px) solid
      light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));
  }
}
